import ApiService from './ApiService'

// export async function apiGetContactByPhone(params) {
//     return ApiService.fetchData({
//         url: '/get-contact-by-phone',
//         method: 'get',
//         params,
//     })
// }

export async function apiGetStaffs(params) {
    return ApiService.fetchData({
        url: '/get-staff-by-store',
        method: 'get',
        params,
    })
}

export async function apiCreateStaff(data) {
    return ApiService.fetchData({
        url: '/create-staff-by-store',
        method: 'post',
        data,
    })
}

