import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    name: '',
    store_id: '',
    partner_id: '',
    email: '',
    phone: '',
    address: '',
    account_type: '',
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        // setUser: (_, action) => {
        //     console.log('-----action-----', action)
        //     action.payload
        // },
        setUser: (state, action) => {
            state.store_id = action.payload.store_id,
            state.partner_id = action.payload.partner_id,
            state.name = action.payload.name,
            state.email = action.payload.email,
            state.phone = action.payload.phone
            state.account_type = action.payload.account_type
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
