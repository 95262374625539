import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'ContactState',
    initialState: {
        deleteConfirmation: false,
        statusConfirmation: false,
        selectedContact: '',
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleStatusConfirmation: (state, action) => {
            state.statusConfirmation = action.payload
        },
        setSelectedContact: (state, action) => {
            console.log('-----action-payload---', action.payload)
            state.selectedContact = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, toggleStatusConfirmation, setSelectedContact } =
    stateSlice.actions

export default stateSlice.reducer
