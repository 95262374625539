import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {apiGetStaffs} from "../../services/StaffService";

export const getStaff = createAsyncThunk(
    'contact/fetchStaff',
    async (params) => {
        console.log('----params---', params)
        const response = await apiGetStaffs(params)
        return response.data
    }
)

export const deleteStaff = async (data) => {
    // const response = await apiDeleteSalesProducts(data)
    // return response.data
}


export const initialTableData = {
    total: 0,
    // state_picking: 'draft',
    store_id: 7,
    pageIndex: 1,
    pageSize: 10,
}

export const initialFilterData = {
    name: '',
    category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    status: [0, 1, 2],
    productStatus: 0,
}

const dataSlice = createSlice({
    name: 'StaffData',
    initialState: {
        loading: false,
        staffList: [],
        staffDetail: {},
        tableData: initialTableData,
        filterData: initialFilterData,
    },
    reducers: {
        // updateProductList: (state, action) => {
        //     state.contactList = action.payload
        // },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setStaffDetail: (state, action) => {
            state.contactDetail = action.payload
        },
    },
    extraReducers: {
        [getStaff.fulfilled]: (state, action) => {
            state.staffList = action.payload.data
            state.tableData.total = action.payload.extend.total
            state.loading = false
        },
        [getStaff.pending]: (state) => {
            state.loading = true
        },
    },
})

export const {
    // updateProductList,
    setTableData,
    setFilterData,
    setStaffDetail,
} = dataSlice.actions

export default dataSlice.reducer
