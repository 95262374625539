import ApiService from './ApiService'

export async function apiGetContactByPhone(params) {
    return ApiService.fetchData({
        url: '/get-contact-by-phone',
        method: 'get',
        params,
    })
}

export async function apiGetContacts(params) {
    console.log('----params----', params)
    return ApiService.fetchData({
        url: '/get-contact-by-store',
        method: 'get',
        params,
    })
}

export async function apiCreateContact(data) {
    return ApiService.fetchData({
        url: '/create-contact',
        method: 'post',
        data,
    })
}

