const appConfig = {
    apiPrefix: 'https://delivery.unicube.vn/unicube_v1',
    // apiPrefix: 'http://localhost/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
