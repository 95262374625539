import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetPickings,
    apiDeleteSalesProducts,
    apiConfirmPicking,
} from 'services/PickingService'

export const getPickings = createAsyncThunk(
    'picking/fetchPicking',
    async (params) => {
        console.log('----params---', params)
        const response = await apiGetPickings(params)
        return response.data
    }
)

export const deleteProduct = async (data) => {
    const response = await apiDeleteSalesProducts(data)
    return response.data
}

export const confirmPicking = async (data) => {
    const response = await apiConfirmPicking(data)
    return response.data
}

export const initialTableData = {
    total: 0,
    store_id: 7,
    pageIndex: 1,
    pageSize: 10,
    phone: '',
    code: '',
}

export const initialFilterData = {
    name: '',
    phone: ''
    // category: ['bags', 'cloths', 'devices', 'shoes', 'watches'],
    // status: [0, 1, 2],
    // productStatus: 0,
}

const dataSlice = createSlice({
    name: 'PickingData',
    initialState: {
        loading: false,
        pickingList: [],
        pickingDetail: {},
        tableData: initialTableData,
        filterData: initialFilterData,
    },
    reducers: {
        updateProductList: (state, action) => {
            state.pickingList = action.payload
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        setFilterData: (state, action) => {
            state.filterData = action.payload
        },
        setPickingDetail: (state, action) => {
            state.pickingDetail = action.payload
        },
    },
    extraReducers: {
        [getPickings.fulfilled]: (state, action) => {
            state.pickingList = action.payload.data
            state.tableData.total = action.payload.extend.total
            state.loading = false
        },
        [getPickings.pending]: (state) => {
            state.loading = true
        },
    },
})

export const {
    updateProductList,
    setTableData,
    setFilterData,
    setPickingDetail,
} = dataSlice.actions

export default dataSlice.reducer
