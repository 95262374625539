import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
    name: 'PickingState',
    initialState: {
        deleteConfirmation: false,
        statusConfirmation: true,
        selectedPicking: '',
    },
    reducers: {
        toggleDeleteConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload
        },
        toggleStatusConfirmation: (state, action) => {
            state.statusConfirmation = action.payload
        },
        setSelectedPicking: (state, action) => {
            console.log('-----action-payload---', action.payload)
            state.selectedPicking = action.payload
        },
    },
})

export const { toggleDeleteConfirmation, toggleStatusConfirmation, setSelectedPicking } =
    stateSlice.actions

export default stateSlice.reducer
