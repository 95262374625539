import ApiService from './ApiService'

export async function apiGetSalesDashboardData(data) {
    return ApiService.fetchData({
        url: '/sales/dashboard',
        method: 'post',
        data,
    })
}

export async function apiGetPickings(params) {
    return ApiService.fetchData({
        url: '/get-picking',
        method: 'get',
        params,
    })
}

export async function apiDeleteSalesProducts(data) {
    return ApiService.fetchData({
        url: '/sales/products/delete',
        method: 'delete',
        data,
    })
}

export async function apiConfirmPicking(data) {
    return ApiService.fetchData({
        url: '/confirm-picking',
        method: 'post',
        data,
    })
}

export async function apiGetPickingById(params) {
    console.log('-----params-----', params)
    return ApiService.fetchData({
        url: '/get-picking-by-id',
        method: 'get',
        params,
    })
}

export async function apiPutSalesProduct(data) {
    return ApiService.fetchData({
        url: '/sales/products/update',
        method: 'put',
        data,
    })
}

export async function apiCreatePicking(data) {
    return ApiService.fetchData({
        url: '/create-receipt',
        method: 'post',
        data,
    })
}

export async function apiCreateOrder(data) {
    return ApiService.fetchData({
        url: '/create-order',
        method: 'post',
        data,
    })
}

export async function apiGetSalesOrders(params) {
    return ApiService.fetchData({
        url: '/sales/orders',
        method: 'get',
        params,
    })
}

export async function apiDeleteSalesOrders(data) {
    return ApiService.fetchData({
        url: '/sales/orders/delete',
        method: 'delete',
        data,
    })
}

export async function apiGetSalesOrderDetails(params) {
    return ApiService.fetchData({
        url: '/sales/orders-details',
        method: 'get',
        params,
    })
}
